body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  --primary: #10658e;
  --primary--dark: #00537f;
  --secondary: #003959;
  --accent: #ffbe00;
  --text: #3c3c3c;
  --text-light: #fff;
  --light-blue: #e6eff3;
  --light-blue-hovered: #daedf7;
  color: var(--text);

  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--light-blue);
}

#inbenta-content {
  max-width: 800px;
  margin: 0 auto;
}

body,
* {
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol' !important;
}

#content {
  padding: 0 16px;
}
#support-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border: none;
  border-radius: 8px;
  padding: 8px 24px;
  height: 40px;
  align-self: center;
  margin-bottom: 55px;
  text-decoration: none;

  background-color: var(--light-blue);
  color: var(--primary);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: var(--light-blue-hovered);
  }

  &.mousedown {
    background-color: var(--light-blue-hovered);
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  background: url('./assets/cloud.png') center no-repeat;
  background-size: cover;
  min-height: 260px;
  margin-bottom: 8px;

  padding: 40px 20px;
  border-bottom: 3px solid var(--accent);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // that will color the search button
  --primary: var(--accent);
  --primary--dark: var(--accent);
}

.title {
  color: var(--text-light);
  font-size: 28px;
  font-weight: 300;
}
.inbenta-km__search__form .form__input.inbenta-km-input {
  height: 48px !important;
  border-radius: 8px 0px 0px 8px !important;
  font-weight: normal !important;
}
.inbenta-km__search {
  height: 48px;
}
.inbenta-km__search__form,
.inbenta-km__results__extended {
  box-shadow: none !important;
}
.form__button .inbenta-km-button {
  height: 48px !important;
  width: 48px;
  border: none !important;
  background: var(--accent) url(/assets/ic_questionmark.svg) center / 30%
    no-repeat !important;
  border-radius: 0px 8px 8px 0px !important;
}
.form__button .inbenta-km-button__icon {
  display: none;
}
.inbenta-km__rating .inbenta-km-input {
  font-weight: normal;
  line-height: 28px;
}
.inbenta-km__results__extended .inbenta-km__faqs {
  padding: 0 !important;
  margin-top: 16px !important;
}

.container__category__group {
  display: block !important;
}
.content-category__icon {
  display: none !important;
}
.inbenta-km__category .content-category__title {
  background: #fff !important;
  padding: 16px !important;
}
.inbenta-km__category {
  margin-right: 0 !important;
}
// hide unnecessary rating in the results: http://gitlab.wetteronline.ffmlan/produkte/apps/mobileapps/pwa/pwa/-/merge_requests/3682
.inbenta-km__results__extended > .inbenta-km__rating {
  display: none;
}

@media (hover: hover) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    @media screen and (min-width: 400px) {
      width: 12px;
      height: 12px;
    }
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    @media screen and (min-width: 400px) {
      border-radius: 2px;
    }
  }
}
